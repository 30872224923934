var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BackBtn',{attrs:{"go_to":_vm.go_back},scopedSlots:_vm._u([{key:"btn-text",fn:function(){return [_vm._v(" Вернуться в каталог ")]},proxy:true}])}),_c('div',{class:_vm.$style.pageTitleWrapper},[_c('h1',{class:[_vm.$style.title, 'text-h1']},[_vm._v(" "+_vm._s(_vm.current_item.nomenclature || '')+" ")]),(_vm.info_message())?_c('InfoAlert',{staticClass:"mb-5",attrs:{"is_dark":""},scopedSlots:_vm._u([{key:"alert_text",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.info_message())}})]},proxy:true}],null,false,631939790)}):_vm._e(),_c('div',{class:_vm.$style.headerRow},[_c('div',{class:_vm.$style.headerRowItem},[_c('span',[_vm._v(" Категория: ")]),_c('span',[_c('b',[_vm._v(" "+_vm._s(_vm.current_item.category_name)+" ")])])]),_c('div',{class:[_vm.$style.headerRowItem, 'd-flex align-center']},[_c('span',{staticClass:"mr-1"},[_vm._v(" Код товара: ")]),_c('span',{class:_vm.$style.productCode},[_c('b',[_vm._v(" "+_vm._s(_vm.current_item.product_code)+" ")])]),_c('v-tooltip',{attrs:{"color":"primary darken-3","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.copy}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("Копировать")])])],1),_c('div',[(_vm.is_favorite)?_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":_vm.remove_from_favorites}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-star")]),_c('span',[_vm._v("Убрать из избранного")])],1):_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":_vm.add_to_favorites}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-star-outline")]),_c('span',[_vm._v("Добавить в избранное")])],1)],1)])],1),_c('v-row',{class:_vm.$style.info},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"8"}},[_c('v-card',{class:_vm.$style.infoCard,attrs:{"outlined":""}},[_c('Tabs',{class:_vm.$style.tabs},[_vm._l((_vm.tabs),function(tab,key){return _c('v-tab',{key:key},[_vm._v(" "+_vm._s(tab)+" ")])}),_c('v-tab-item',{class:_vm.$style.tab,attrs:{"keep-alive":""}},[_c('Characteristics')],1),_c('v-tab-item',{class:_vm.$style.tab,attrs:{"keep-alive":""}},[_c('Files')],1)],2),(_vm.slides.length)?_c('Slider',{class:_vm.$style.slider,attrs:{"slides":_vm.slides,"options":_vm.opt,"name":"ProductDetail","default_pagination":"","default_navigation":"","allowSlideNext":false},scopedSlots:_vm._u([{key:"slide",fn:function(ref){
var slide = ref.slide;
return [_c('div',{class:_vm.$style.slide},[_c('img',{class:_vm.$style.slideImg,attrs:{"src":slide,"alt":""}})])]}}],null,false,3162783166)}):_vm._e()],1)],1),_c('v-col',{class:_vm.$style.prices,attrs:{"cols":"12","sm":"12","lg":"4"}},[_c('v-card',{class:_vm.$style.pricesCard,attrs:{"outlined":""}},[_c('PricesCol',{class:_vm.$style.priceCol}),_c('div',{class:_vm.$style.addToCartBlock},[_c('TextField',{class:_vm.$style.quantityInput,attrs:{"type":"number","hide-details":"","dense":"","background-color":"#fff","clearable":false,"max":_vm.max_quantity,"min":0,"disabled":_vm.is_qty_disabled},on:{"input":_vm.upd_item_cart_quantity},model:{value:(_vm.product_quantity),callback:function ($$v) {_vm.product_quantity=$$v},expression:"product_quantity"}}),_c('v-tooltip',{attrs:{"left":"","color":"primary darken-3","open-delay":"300","disabled":_vm.is_available_to_add_cart},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('CartBtn',{attrs:{"product":_vm.current_item,"disabled":!_vm.is_not_accessible_role() || !_vm.is_available_to_add_cart},on:{"add":_vm.add_to_cart}})],1)]}}])},[_c('div',{staticStyle:{"width":"320px"}},[_c('span',[_vm._v(_vm._s(_vm.tooltip_message_cart_btn))])])])],1),_vm._v(" "),_c('ul',{class:[_vm.$style.avaliables, 'pl-0']},[_c('li',{class:_vm.$style.avaliable},[_vm._v(" Доступные остатки: "),_c('b',[_vm._v(" "+_vm._s(_vm.get_avaliables('available_balances'))+" ")])]),_c('li',{class:_vm.$style.avaliable},[_vm._v(" Доступные остатки после производства: "),_c('b',[_vm._v(" "+_vm._s(_vm.get_avaliables('available_post_production_residues'))+" ")])])])],1)],1)],1),_c('ProjectChoosenDialog',{on:{"close":function($event){_vm.cart_blocked_dialog = false}},model:{value:(_vm.cart_blocked_dialog),callback:function ($$v) {_vm.cart_blocked_dialog=$$v},expression:"cart_blocked_dialog"}}),_c('FrontolCheckDialog',{attrs:{"related_products":_vm.related_products,"product":_vm.product},on:{"upd_qty":_vm.upd_related_qty,"confirm":_vm.add_to_cart_confirmed}}),_c('SimpleDialog',{attrs:{"max-width":"564px","bts_align":"center","center_text":"","center_title":""},on:{"close":_vm.close_accreditation_dialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Внимание ")]},proxy:true},{key:"text",fn:function(){return [_c('div',{class:_vm.$style.link,domProps:{"innerHTML":_vm._s(_vm.accreditation_popup_message)}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"depressed":"","color":"accent darken-4"},on:{"click":_vm.close_accreditation_dialog}},[_vm._v(" Понятно ")])]},proxy:true}]),model:{value:(_vm.accreditation_message_dialog),callback:function ($$v) {_vm.accreditation_message_dialog=$$v},expression:"accreditation_message_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }